import {serviceApi } from '../url';
import {request} from '../globalAxios';
import RequestResponse from '../../domain/model/request_responce';
import EventPricingModel from '../../domain/model/event_pricing_model';
import { log } from './util_api';

const creatEventPricing = async (element,event,key) => {

  console.info("************* Pricing create ************");
  var data = {
    "cost": Number(element.amount),
    "tax" : 0.0,
    "nbrTicket": Number(element.numberPlace),
    "collation": element.options,
    "priceCategorie" :element.category,
    "free": false,
    "activate" : true,
    "eventId": event.data.id
  };
  log("*********** pricing data: ",data);
    
    const response =  await request('post', serviceApi.CREAT_EVENT_PRICING,data,key);
    
    return new RequestResponse(response.data,response.sucess,response.pageSize,response.page,response.exception);
  };

  const getEventPricings = async (id) => {
    const response =  await request('get', serviceApi.GET_ALL_EVENT_PRICING+"?id="+id+"&page=0&size=12");    
    return new RequestResponse(response.data.map((pricing)=>new EventPricingModel(pricing)),response.sucess,response.pageSize,response.page,response.totalElement,response.totalPages,response.exception);
  };
  
  export {creatEventPricing,getEventPricings}; 