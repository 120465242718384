import toast from "react-hot-toast";
import { checkToken } from "../../data/api/member";
import { log } from "../../data/api/util_api";


const isTokenValide = async () => {
    log("isTokenValide is call --");

    ///check if current token is valide
    return await checkToken().then(async (resp)=>{
      if(resp.sucess ){        
        log("first call ---");
        return resp.data;
      }else{
        //toast.alert(resp.message);
        log("second call ---");
        return false;
      }
      
    });
  };

export {isTokenValide} 