import {create} from 'zustand';
import { getEvents,getEventsByCategory,getEventsByPeriod ,getEventsDetail} from '../../data/api/event_api';
import { getDateInterval } from '../utils';
import { log } from '../../data/api/util_api';

const eventStore = create((set,get) => ({
  events: [],
  
  eventFilter:[],
  loading: false, 
  error: null,
  currentPage: 1,
  currentPaginationStart: 1,
  page:0,
  currentFilterKey: "all_event",
  pageSize:12,
  totalElement: 0,
  setCurrentPage: (page)=>{
    set({currentPage:page});
    get().fetchFilterEvent(get().currentFilterKey,page);    
  },
  setCurrentPaginationStart: (page)=>{
    set({currentPaginationStart:page})
  },
  fetchEvents: async (page) => {
    set({ loading: true, error: null });
    try {
      const events = await getEvents(page,get().pageSize);
      if(events.data !==null){
        set({ events:events.data,eventFilter:events.data, loading: false ,totalElement:events.totalElements}); 
        
      }else{
        set({loading: false });
        log("************************* error ****************************");
      }
      
    } catch (error) {
      set({ error: error.message, loading: false });
    }
  },
  fetchFilterEvent: async (keyFilter,page)=>{

    set({currentFilterKey:keyFilter,currentPage:page});
    set({ loading: true, error: null });
    var events = {data:[]};
    try {
      if(get().currentFilterKey==="all_event"||get().currentFilterKey==="all event"){
        get().fetchEvents(page);
        //events = await getEventsByCategory(get().currentFilterKey,page,get().pageSize);
      }else{
        if(get().currentFilterKey==="all_event"||get().currentFilterKey==="free"||get().currentFilterKey==="get_start"||get().currentFilterKey==="for_you"){
          events = await getEventsByCategory(get().currentFilterKey,page,get().pageSize);
        }else{
          if(get().currentFilterKey==="today"||get().currentFilterKey==="this_week" ||get().currentFilterKey==="this_month" || get().currentFilterKey==="this_weekend"){
            var period = getDateInterval(get().currentFilterKey); 
            events = await getEventsByPeriod(period,page,get().pageSize);
          }else{
            events = await getEventsByCategory(get().currentFilterKey,page,get().pageSize);            
          }
        }
      }
      
      
      
      if(events.data !==null){
        set({ eventFilter:events.data, loading: false ,totalElement:events.totalElements});    
      }else{
        set({loading: false });
        log("************************* error ****************************");
      }
      
    } catch (error) {
      set({ error: error.message, loading: false });
    }
    //set({ eventFilter:get().events, loading: false });    
  }
}));

export default eventStore;