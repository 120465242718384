import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

const AboutUs = ()=>{

    const {t} = useTranslation(['about_us','common']);

    return (
        <Fragment>
           <main id="content" className="site-main">
                {/*<!-- Inner Banner html start-->{t('COMMON_SEARCH',{ns:"common"})}*/}
                <section className="inner-banner-wrap">
                    <div className="inner-baner-container">
                        <div className="container">
                            <div className="inner-banner-content">
                                <h2 className="inner-title">{t('COMMON_ABOUTUS',{ns:"common"})}</h2><br/>
                            </div>
                        </div>
                        <div className="overlay-image"></div>
                        <div className="bottom-shape">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                                <path className="elementor-shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                                c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                                c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
                            </svg>
                        </div>
                    </div>
                </section>
                {/*<!-- About start -->*/}
                <div className="about-page-section">
                    <div className="about-inner-page">
                        <div className="container">
                            <div className="section-heading">
                                <div className="row align-items-end">
                                    <div className="col-lg-6">
                                        <div className="sub-title">{t('ART_TITLE1')}</div>
                                        <h2 className="section-title">{t('ART_P1')}. </h2>
                                        <figure className="about-img">
                                            <img src="assets/images/aboutus.png" alt="numerique_ticket_aboutus"/>
                                        </figure>
                                    </div>
                                    <div className="col-lg-6">
                                        <figure className="about-img">
                                            <img src="assets/images/solution.png" alt=""/>
                                        </figure>
                                        <div className="sub-title">{t('ART_TITLE2')}</div>
                                        <h2 className="section-title">{t('ART_P1')}.</h2>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<!-- home team section html start -->*/}
                    <section className="team-section">
                    <div className="container">
                        <div className="section-heading text-center">
                            <div className="row">
                                <div className="col-lg-8 offset-lg-2">
                                <div className="sub-title">{t("OUR_CREATIVE_TEAM")}</div>
                                <h2 className="section-title">{t("OUR_CREATIVE_TEAM_TITLE")}</h2>
                                <p>{t("OUR_CREATIVE_TEAM_P")}.</p>
                                </div>
                            </div>
                        </div>
                        <div className="team-inner">
                            <div className="row justify-content-md-center">
                                <div className="col-lg-3 col-md-6">
                                    <article className="team-item">
                                        <figure className="team-img" style={{
                                            width: '100%',
                                            height: '300px',
                                            overflow: 'hidden'
                                        }}>
                                            <img 
                                                src="assets/images/ceo.png" 
                                                alt=""
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    objectPosition: 'center'
                                                }}
                                            />
                                            <div className="social-links">
                                                <ul>
                                                <li>
                                                    <a href="https://www.linkedin.com/in/dongmo-herve-3a497163/" target="_blank">
                                                        <i className="fab fa-linkedin" aria-hidden="true"></i>
                                                    </a>
                                                </li>
                                                </ul>
                                            </div>
                                        </figure>
                                        <div className="team-content">
                                            <h4>Dongmo Herve</h4>
                                            <span>CEO</span>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <article className="team-item">
                                        <figure className="team-img" style={{
                                            width: '100%',
                                            height: '300px',
                                            overflow: 'hidden'
                                        }}>
                                            <img 
                                                src="assets/images/about_us/p1.JPG" 
                                                alt=""
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    objectPosition: 'center'
                                                }}
                                            />
                                            <div className="social-links">
                                                <ul>
                                                <li>
                                                    <a href="https://www.linkedin.com/in/eloundou-c%C3%A9lestin-placide/" target="_blank">
                                                        <i className="fab fa-linkedin" aria-hidden="true"></i>
                                                    </a>
                                                </li>
                                                </ul>
                                            </div>
                                        </figure>
                                        <div className="team-content">
                                        <h4>Eloundou Celestin</h4>
                                        <span>PROJECT MANAGER</span>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <article className="team-item">
                                        <figure className="team-img" style={{
                                            width: '100%',
                                            height: '300px',
                                            overflow: 'hidden'
                                        }}>
                                            <img 
                                                src="assets/images/about_us/p5.jpeg" 
                                                alt=""
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    objectPosition: 'center'
                                                }}
                                            />
                                            <div className="social-links">
                                                <ul>
                                                <li>
                                                    <a href="https://www.linkedin.com/in/edkins-gael-kouna-eteme-2b002b90/" target="_blank">
                                                        <i className="fab fa-linkedin" aria-hidden="true"></i>
                                                    </a>
                                                </li>
                                                </ul>
                                            </div>
                                        </figure>
                                        <div className="team-content">
                                        <h4>Edkins Gael Kouna</h4>
                                        <span>DEVOPS</span>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div className="row justify-content-md-center">
                                
                                <div className="col-lg-3 col-md-6">
                                    <article className="team-item">
                                        <figure className="team-img" style={{
                                            width: '100%',
                                            height: '300px',
                                            overflow: 'hidden'
                                        }}>
                                            <img 
                                                src="assets/images/about_us/p2.jpeg" 
                                                alt=""
                                                style={{
                                                    width: '100%', 
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    objectPosition: 'center'
                                                }}
                                            />
                                            <div className="social-links">
                                                <ul>
                                                <li>
                                                    <a href="https://www.linkedin.com/in/majella-elobo-ekassi-8b212877" target="_blank">
                                                        <i className="fab fa-linkedin" aria-hidden="true"></i>
                                                    </a>
                                                </li>
                                                </ul>
                                            </div>
                                        </figure>
                                        <div className="team-content">
                                        <h4>Majella Elobo</h4>
                                        <span>DEVELOPPER</span>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <article className="team-item">
                                        <figure className="team-img" style={{
                                            width: '100%',
                                            height: '300px',
                                            overflow: 'hidden'
                                        }}>
                                            <img  src="assets/images/about_us/p3.jpeg" alt="" style={{
                                                    width: '100%', 
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    objectPosition: 'center'
                                                }} />
                                            <div className="social-links">
                                                <ul>
                                                <li>
                                                    <a href="https://www.linkedin.com/in/vincent-joel-djama-672419225" target="_blank">
                                                        <i className="fab fa-linkedin" aria-hidden="true"></i>
                                                    </a>
                                                </li>
                                                </ul>
                                            </div>
                                        </figure>
                                        <div className="team-content">
                                            <h4>DJAMA Vincent</h4>
                                            <span>DEVELOPPER</span>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <article className="team-item">
    <figure className="team-img" style={{
                                            width: '100%',
                                            height: '300px',
                                            overflow: 'hidden'
                                        }}>
                                            <img src="assets/images/about_us/p4.jpeg" alt=""/>
                                            <div className="social-links">
                                                <ul>    
                                                <li>
                                                    <a href="https://www.linkedin.com/in/marie-nguekame-kamga-961885173" target="_blank">
                                                        <i className="fab fa-linkedin" aria-hidden="true"></i>
                                                    </a>
                                                </li>
                                                </ul>
                                            </div>
                                        </figure>
                                        <div className="team-content">
                                            <h4>Marie Nguekame</h4>
                                            <span>QA</span>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="team-left-shape"></div>
                    </section>
                    
                </div>
           </main>
        </Fragment>
    );
}

export default AboutUs;

