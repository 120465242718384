import { Link } from "react-router-dom";
import CreateEventService from "../../domain/usecase/create_event_service";
import { useEffect, useState } from "react";
import { Autocomplete, Chip, FormControl, InputLabel, Select, TextField } from "@mui/material";
import FormErrorFieldMessage from "../form_error_feild";
import { el } from "date-fns/locale";
import { log } from "../../data/api/util_api";


const Step1 = ()=>{

    const { fetchCategory,category, loadingCat, switchStep, getAllTags ,tags, showListTags,loadingTags,showLisCat,
        setEventData,
        eventData,
        currentTag,
        setCurrentTag,
        step1Error,setstep1Error
    } = CreateEventService();
    
    //const [inputValue, setInputValue] = useState('');

    // const handleDelete = (tagToDelete) => () => {
    //     setTags(tags.filter((tag) => tag !== tagToDelete));
    // };

    // const handleAdd = (event, newTags) => {
    //     setTags(newTags);
    // };
  
    useEffect(() => {           
        if(!showLisCat)
        fetchCategory();
      }, [fetchCategory]);

    const Cat = ({keyid,name})=>{
    return(
        <>
            <Link to="" key={keyid} className="outline-round my-outline-round" onClick={()=>{
                setEventData("typeid",keyid);
                setCurrentTag([]);
                getAllTags(keyid);
            }} >{name}</Link>
        </>
    );
    }

    return(
        <>
            <div className="checkout-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 ">
                            <div className="checkout-field-wrap">
                                <h3>Let’s get to know you first!</h3>
                                <span>Tell us what kind of events you want to host and we’ll help make it happen.</span>
                                {/* **************************** select option **********************/}
                                <div className="row mt-4">
                                    <h4>On average, how big are your events? * <FormErrorFieldMessage message={step1Error[0]}  /></h4>      
                                        <div className="col-12">
                                        
                                            <FormControl sx={{ marginTop: 1, minWidth: "100%" }}>
                                                <InputLabel htmlFor="grouped-native-select">Select Option</InputLabel>
                                                <Select native defaultValue="" id="grouped-native-select" 
                                                label="Grouping" onChange={(e)=>{ 
                                                    log("option ",e.target.value);
                                                    setEventData("quantity",e.target.value);
                                                    setstep1Error(1,"");
                                                    log("select "+e.target.value);
                                                    }}>
                                                    
                                                    <optgroup label="average place"  value={eventData.quantity}>
                                                        <option value={0}>Select minimun number of guest</option>
                                                        <option value={50}>Up to 50</option>
                                                        <option value={500}>Up to 500</option>
                                                        <option value={5000}>Up to 5000</option>
                                                        <option value={50000}>More than 50 000</option>
                                                        <option value={-1}>I'm not shure yet</option>
                                                    </optgroup>
                                                </Select>
                                            </FormControl>
                                        </div>
                                </div>
                                <div className="coupon-field">
                                    <div className="row mt-5">        
                                        <h4>What type of events do you host? * <FormErrorFieldMessage message={step1Error[1]}  /></h4>                
                                        <div className="post-socail-wrap">
                                            <div className="social-icon-wrap ">
                                            {loadingCat&&<div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                                </div>}
                                                {loadingCat&&<div>Loading</div>}
                                                 {showLisCat&&category!=null&&category.map((item)=><Cat keyid={item.id} name={item.name} />)}   
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                        
                                {/** ****************** tags **************************** */}
                                <h4>Select some tag for your event * <FormErrorFieldMessage message={step1Error[2]}  /></h4>
                                {showListTags&&!loadingTags&&<Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    options={tags} 
                                    getOptionLabel={(option) => option.name}
                                    value={currentTag}
                                    onChange={(event,e)=>{
                                        const dataTag = e.map(item => item.name).join(':');
                                        log(dataTag);
                                        setCurrentTag(e);
                                        setEventData("tag",dataTag);
                                    }}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                    <TextField 
                                        {...params}
                                        label="Select Tag"
                                        placeholder="Favorites"
                                    />
                                    )}
                                    sx={{ marginTop: 2,minWidth: "100%" }} 
                                />}
                                {loadingTags&&<div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                                </div>}
                                <div className="row mt-4">
                                    {/*<!-- post navigation html -->*/}
                                    <div className="post-navigation">
                                        <div className="nav-prev">
                                            <a href="#">
                                            <span className="nav-label">Cancel</span>
                                            <span className="nav-title">Cancel event creation</span>
                                            </a>
                                        </div>
                                        <div className="nav-next" onClick={()=>{
                                            var errorDetected = false;
                                            if(eventData.quantity===0){
                                                log("eventData.quantity===0");
                                                log("eventData.quantity in ",eventData.quantity);
                                                setstep1Error(1,"This field is require, please select an option")
                                                errorDetected = true;
                                            }else{
                                                setstep1Error(1,"");
                                                log("eventData.quantity===0",eventData.quantity);
                                            }
                                            if(eventData.typeid == null){
                                                log("eventData.typeid == null");
                                                log("eventData.typeid in ",eventData.typeid);
                                                setstep1Error(2,"This field is require, please select a categorie");
                                                errorDetected = true;
                                            }else{
                                                setstep1Error(2,"")
                                            }
                                            if(eventData.tag === ""){
                                                log("eventData.tag === ");
                                                log("eventData.tag in ",eventData.tag);
                                                setstep1Error(3,"This field is require, please select some Tags");
                                                errorDetected = true;
                                            }else{
                                                setstep1Error(3,"")
                                            }
                                            if(!errorDetected)
                                                switchStep(2)
                                        }}>
                                            <a href="#">
                                            <span className="nav-label">Next2</span>
                                            <span className="nav-title">Enter envent details</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    

}

export  default Step1