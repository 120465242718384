import { Link, useParams } from "react-router-dom";
import eventStore from "../domain/usecase/event_service";
import MainEventTicketItem from "../components/main_event_ticket/main_event_ticket_item";
import { useEffect } from "react";
import TagFilter from "../components/tag_filter";
import EventPagination from "../components/pagination";
import CategorieLoader from "../components/shommer loader/categorie_loadeer";
import ContainLoader from "../components/shommer loader/containt_loader";


const FilterEvent = ()=>{
    const { fetchFilterEvent, loading, error,eventFilter,pageSize,currentFilterKey,totalElement } = eventStore();
    const { filterKey } = useParams();

    useEffect(() => {
      fetchFilterEvent(currentFilterKey,1); 
      }, [fetchFilterEvent,currentFilterKey]);

    if (loading) return(
            <div className="product-outer-wrap product-wrap">
                <div className="container">
                <div className="product-notices-wrapper">
                {[1, 2, 3,4].map((_, index) => <CategorieLoader key={index}/> )}
                </div>
                <div className="product-item-wrapper">
                {[1, 2, 3].map((_, index) => <ContainLoader key={index}/> )}
                </div>
                </div>
                
                
            </div>
        );
   if (eventFilter!=null&&error) return <p>Error: {error}</p>;


    return(
        <>
            <div className="product-outer-wrap product-wrap"> 
                <div className="container">
                    <div >
                        {/*bloc filter event*/}
                        <div className="product-notices-wrapper">
                        <form method="get col-md-4">
                           <select name="orderby" className="orderby" aria-label="Shop order">
                            <option value="menu_order"  >All Place</option>
                              <option value="menu_order"  >My Location</option>
                              <option value="rating">My Town</option>
                              <option value="rating">My Country</option>
                              <option value="date">Auther Country </option>                                 
                           </select>
                           <div className="post-navigation-wrap">                            
                        </div>
                           </form>
                           <TagFilter />
                           
                        </div>
                        <div className="product-item-wrapper">
                            <div className="row">
                              {eventFilter!=null&&eventFilter.map((event)=> <MainEventTicketItem key={event.id} event={event}/>)}
                            </div>
                        </div>
                        {/*pagination html start*/}
                        {/*<!-- pagination html start-->*/}
                        
                        <EventPagination 
                          action={(page, size) => fetchFilterEvent(filterKey,page) }
                          size={totalElement} 
                          pageSize={pageSize} 
                        />
                        {/*<!-- pagination html start-->*/}
                    </div>
                </div>
            </div>
        </>
    )
}

export default FilterEvent;