class PayResponse {

    constructor(
        reference,
        operator,
        ussd_code,
        external_reference
    ){
        this.reference=reference;
        this.operator=operator;
        this.ussd_code=ussd_code;
        this.external_reference=external_reference;
    }
}

export default PayResponse;