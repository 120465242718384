import React, { useEffect } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import PricingStore from '../../domain/usecase/model_pricing_service';
import { log } from '../../data/api/util_api';

const ModalCreatePricing = ({action,keyPlace}) => {
  //const [show, setShow] = useState(false);

  //const handleClose = () => setShow(false);
  
  //const handleShow = () => setShow(true);

  const {show,showUpdate,initData, toggleShow,toggleShowUpdate,setPricingItem, addPricing ,updatePricing,pricingItem } = PricingStore();

  useEffect(() => {
   if(action==="create") initData();
  }, []);
  
    return (
        <>
          {action==="create"&&<button className="button-round" onClick={toggleShow} >
            Add pricing
          </button>}
          {action!=="create"&&<button className="button-round" onClick={()=>toggleShowUpdate(keyPlace,true)} >
            Update
          </button>}
    
          <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex={action==="create"?"-1":"-2"} role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{action==="create"?"Add":"Update"} pricing</h5>
                  <button type="button" className="close" aria-label="Close" onClick={toggleShow}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>  
                <div className="modal-body">
                <main id="content" className="site-main">
                    {/*<!-- Inner Banner html start-->*/}
                    <div className="cart-section">
                    <div className="container">
                    <div className="checkout-field-wrap">
                        <h3>{action==="create"?"Add":"Update"} your event pricing</h3>
                        <span>Define pricing and advantage for .</span>
                        <div className="row">
                        <div className="col-md-12">
                            <div className="col mt-4">
                                <h5>Enter Ticket categorie - {action}</h5>
                                <TextField id="outlined-uncontrolled"  label="Ticket Category"  value={pricingItem.category}
                                onChange={(v)=>{
                                  log(v.target.value);
                                  setPricingItem("category",v.target.value);
                                  setPricingItem("id",v.target.value);
                                }}
                                placeholder="Exp: VIP, PREMIUM, CLASSIC, CHABAT"  
                                sx={{ minWidth: "100%" }}  
                                InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-clone"/></InputAdornment>,}} />
                            </div>
                            <div className="col mt-4">
                                <h5>Enter Ticket amount for this category</h5>
                                <TextField id="outlined-uncontrolled" type="number" label="Ticket Amount"  value={pricingItem.amount}
                                placeholder="Enter amount"  sx={{ minWidth: "100%" }}  
                                onChange={(v)=>{
                                  log(v.target.value);
                                  setPricingItem("amount",v.target.value);
                                }}
                                InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-money-bill"/></InputAdornment>,}} />
                            </div>
                            <div className="col mt-4">
                                <h5>Enter number of ticket for this price</h5>
                                <TextField id="outlined" type="number" label="Number of ticket" value={pricingItem.numberPlace}
                                onChange={(v)=>{
                                  log(v.target.value);
                                  setPricingItem("numberPlace",v.target.value);
                                }}
                                placeholder="Enter number of ticket"  sx={{ minWidth: "100%" }}  
                                InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-ticket-alt"/></InputAdornment>,}} />
                            </div>
                            <div className="col mt-4">
                                <h5>Enter Ticket option</h5>
                                <span>Here you can {action==="create"?"Add":"Update"} some additional option customer have if he buy this ticket</span>
                                <TextField id="outlined-uncontrolled"  label="Ticket options"   value={pricingItem.options}
                                onChange={(v)=>{
                                  log(v.target.value);
                                  setPricingItem("options",v.target.value);
                                }}
                                placeholder="Exemple: Tishert+wisky+..."  sx={{ minWidth: "100%", marginTop: "15px"}}  
                                InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-magic"/></InputAdornment>,}} />
                            </div>
                            <div className="col mt-4" >
                                <button className="button-round"  onClick={()=>{
                                  addPricing();
                                  toggleShow();
                                }} >
                                    Add Pricing
                                </button>
                            </div>
                        </div>
                
                        </div>
                        
                    </div> 
                    </div>
                    </div>
                </main>
                
                </div>
                
              </div>
            </div>
          </div>

          <div className={`modal ${showUpdate ? 'show' : ''}`} style={{ display: showUpdate ? 'block' : 'none' }} tabIndex={action==="create"?"-1":"-2"} role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Update pricing</h5>
                  <button type="button" className="close" aria-label="Close" onClick={()=>toggleShowUpdate(keyPlace,false)}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>  
                <div className="modal-body">
                <main id="content" className="site-main">
                    {/*<!-- Inner Banner html start-->*/}
                    <div className="cart-section">
                    <div className="container">
                    <div className="checkout-field-wrap">
                        <h3>Update your event pricing</h3>
                        <span>Change event pricing data and save</span>
                        <div className="row">
                        <div className="col-md-12">
                            <div className="col mt-4">
                                <h5>Enter Ticket categorie - {action}</h5>
                                <TextField id="outlined-uncontrolled"  label="Ticket Category"  value={pricingItem.category}
                                onChange={(v)=>{
                                  log(v.target.value);
                                  setPricingItem("category",v.target.value)
                                }}
                                placeholder="Exp: VIP, PREMIUM, CLASSIC, CHABAT"  
                                sx={{ minWidth: "100%" }}  
                                InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-clone"/></InputAdornment>,}} />
                            </div>
                            <div className="col mt-4">
                                <h5>Enter Ticket amount for this category</h5>
                                <TextField id="outlined-uncontrolled" type="number" label="Ticket Amount"  value={pricingItem.amount}
                                placeholder="Enter amount"  sx={{ minWidth: "100%" }}  
                                onChange={(v)=>{
                                  log(v.target.value);
                                  setPricingItem("amount",v.target.value);
                                }}
                                InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-money-bill"/></InputAdornment>,}} />
                            </div>
                            <div className="col mt-4">
                                <h5>Enter number of ticket for this price</h5>
                                <TextField id="outlined" type="number" label="Number of ticket"  value={pricingItem.numberPlace}
                                onChange={(v)=>{
                                  log(v.target.value);
                                  setPricingItem("numberPlace",v.target.value);
                                }}
                                placeholder="Enter number of ticket"  sx={{ minWidth: "100%" }}  
                                InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-ticket-alt"/></InputAdornment>,}} />
                            </div>
                            <div className="col mt-4">
                                <h5>Enter Ticket option</h5>
                                <span>Here you can {action==="create"?"Add":"Update"} some additional option customer have if he buy this ticket</span>
                                <TextField id="outlined-uncontrolled"  label="Ticket options"  value={pricingItem.options}
                                onChange={(v)=>{
                                  log(v.target.value);
                                  setPricingItem("options",v.target.value);
                                }}
                                placeholder="Exemple: Tishert+wisky+..."  sx={{ minWidth: "100%", marginTop: "15px"}}  
                                InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-magic"/></InputAdornment>,}} />
                            </div>
                            <div className="col mt-4" >
                                <button className="button-round"  onClick={()=>updatePricing(pricingItem.id)} >
                                    Save Change
                                </button>
                            </div>
                        </div>
                
                        </div>
                        
                    </div> 
                    </div>
                    </div>
                </main>
                
                </div>
                
              </div>
            </div>
          </div>
          {show && <div className="modal-backdrop fade show"></div>}
          {showUpdate && <div className="modal-backdrop fade show"></div>}
        </>
      );
  
};

export default ModalCreatePricing;
