import React, { useState } from 'react';
import { TextField } from '@mui/material';
import FormErrorFieldMessage from '../form_error_feild';

const FormPayementBankCard = () => {
    const [cardData, setCardData] = useState({
        fullName: '',
        cardNumber: '',
        expireDate: '',
        cvv: ''
    });

    const [errors, setErrors] = useState({
        fullName: { error: false, message: '' },
        cardNumber: { error: false, message: '' },
        expireDate: { error: false, message: '' },
        cvv: { error: false, message: '' }
    });

    const handleChange = (field, value) => {
        let formattedValue = value;
        let error = { error: false, message: '' };

        switch (field) {
            case 'fullName':
                // Only letters and spaces
                formattedValue = value.replace(/[^A-Za-z\s]/g, '');
                if (formattedValue.length < 3) {
                    error = { error: true, message: 'Name must be at least 3 characters' };
                }
                break;

            case 'cardNumber':
                // Remove non-digits and format with spaces
                formattedValue = value.replace(/\D/g, '').replace(/(\d{4})/g, '$1 ').trim();
                if (formattedValue.length > 19) { // 16 digits + 3 spaces
                    formattedValue = formattedValue.slice(0, 19);
                }
                if (!/^\d{4}\s?\d{4}\s?\d{4}\s?\d{4}$/.test(formattedValue.replace(/\s/g, ''))) {
                    error = { error: true, message: 'Invalid card number' };
                }
                break;

            case 'expireDate':
                // Format MM/YY
                formattedValue = value
                    .replace(/\D/g, '')
                    .replace(/(\d{2})(\d)/, '$1/$2')
                    .slice(0, 5);
                
                if (formattedValue.length === 5) {
                    const [month, year] = formattedValue.split('/');
                    const currentYear = new Date().getFullYear() % 100;
                    const currentMonth = new Date().getMonth() + 1;
                    
                    if (parseInt(month) < 1 || parseInt(month) > 12) {
                        error = { error: true, message: 'Invalid month' };
                    } else if (parseInt(year) < currentYear || 
                            (parseInt(year) === currentYear && parseInt(month) < currentMonth)) {
                        error = { error: true, message: 'Card expired' };
                    }
                }
                break;

            case 'cvv':
                // Only numbers, max 4 digits
                formattedValue = value.replace(/\D/g, '').slice(0, 4);
                if (formattedValue.length < 3) {
                    error = { error: true, message: 'CVV must be 3-4 digits' };
                }
                break;
        }

        setCardData(prev => ({ ...prev, [field]: formattedValue }));
        setErrors(prev => ({ ...prev, [field]: error }));
    };

    return (
        <div className='row mt-2'>
            <div className="col-12">
                <div className="form-group">
                    {errors.fullName.error && <FormErrorFieldMessage message={errors.fullName.message} />}
                    <TextField
                        fullWidth
                        label="Full Name on Card *"
                        value={cardData.fullName}
                        onChange={(e) => handleChange('fullName', e.target.value)}
                        error={errors.fullName.error}
                    />
                </div>
            </div>
            <div className="col-12">
                <div className="form-group">
                    {errors.cardNumber.error && <FormErrorFieldMessage message={errors.cardNumber.message} />}
                    <TextField
                        fullWidth
                        label="Card Number *"
                        value={cardData.cardNumber}
                        onChange={(e) => handleChange('cardNumber', e.target.value)}
                        error={errors.cardNumber.error}
                        placeholder="1234 5678 9012 3456"
                    />
                </div>
            </div>
            <div className="col-sm-6">
                <div className="form-group">
                    {errors.expireDate.error && <FormErrorFieldMessage message={errors.expireDate.message} />}
                    <TextField
                        fullWidth
                        label="Expiration Date *"
                        value={cardData.expireDate}
                        onChange={(e) => handleChange('expireDate', e.target.value)}
                        error={errors.expireDate.error}
                        placeholder="MM/YY"
                    />
                </div>
            </div>
            <div className="col-sm-6">
                <div className="form-group">
                    {errors.cvv.error && <FormErrorFieldMessage message={errors.cvv.message} />}
                    <TextField
                        fullWidth
                        label="CVV *"
                        value={cardData.cvv}
                        onChange={(e) => handleChange('cvv', e.target.value)}
                        error={errors.cvv.error}
                        placeholder="123"
                    />
                </div>
            </div>
        </div>
    );
};

export default FormPayementBankCard;

