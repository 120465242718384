import React from 'react';
import { Paper, Typography, Box, Divider } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import GavelIcon from '@mui/icons-material/Gavel';
import BlockIcon from '@mui/icons-material/Block';

const TermsConditions = () => {
    const primaryColor = '#D1711F';
    const secondaryColor = '#263238';

    const styles = {
        container: {
            padding: '2rem',
            maxWidth: '800px',
            margin: '0 auto',
            backgroundColor: '#fff',
            borderRadius: '12px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        },
        header: {
            color: primaryColor,
            fontWeight: 'bold',
            marginBottom: '1.5rem',
            textAlign: 'center',
        },
        sectionTitle: {
            color: primaryColor,
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            marginTop: '2rem',
            marginBottom: '1rem',
        },
        text: {
            color: secondaryColor,
            marginBottom: '1rem',
        },
        listItem: {
            marginBottom: '0.5rem',
            color: secondaryColor,
        },
        divider: {
            margin: '2rem 0',
            backgroundColor: primaryColor,
            opacity: 0.2,
        },
        icon: {
            color: primaryColor,
        },
    };

    return (
        <Paper elevation={3} style={styles.container}>
            <Typography variant="h4" style={styles.header}>
                Terms and Conditions of Use
            </Typography>

            <Typography style={styles.text}>
                By using this platform, you agree to the following terms:
            </Typography>

            <Box>
                <Typography variant="h6" style={styles.sectionTitle}>
                    <SecurityIcon style={styles.icon} />
                    1. User Responsibilities & Liabilities
                </Typography>
                <ul>
                    <li style={styles.listItem}>
                        <strong>Ticket Security:</strong> You acknowledge that DEvent is not responsible for stolen, lost, or misused tickets. Protect your tickets like cash.
                    </li>
                    <li style={styles.listItem}>
                        <strong>Authentic Events:</strong> You are prohibited from creating fake events, false listings, or misleading event information.
                    </li>
                    <li style={styles.listItem}>
                        <strong>No Fraudulent Sales:</strong> If you create an event, you must deliver it as promised. Intentionally canceling after selling tickets may result in legal action.
                    </li>
                </ul>
            </Box>

            <Divider style={styles.divider} />

            <Box>
                <Typography variant="h6" style={styles.sectionTitle}>
                    <PrivacyTipIcon style={styles.icon} />
                    2. Data Privacy & Usage
                </Typography>
                <ul>
                    <li style={styles.listItem}>
                        <strong>Exclusive Data Control:</strong> DEvent is the sole authorized entity allowed to process or use your personal data for platform operations.
                    </li>
                    <li style={styles.listItem}>
                        <strong>No Third-Party Sharing:</strong> We do not sell, rent, or share your data with external marketers or unauthorized parties without your explicit consent.
                    </li>
                </ul>
            </Box>

            <Divider style={styles.divider} />

            <Box>
                <Typography variant="h6" style={styles.sectionTitle}>
                    <GavelIcon style={styles.icon} />
                    3. Event Cancellations & Refunds
                </Typography>
                <ul>
                    <li style={styles.listItem}>
                        <strong>Organizer Obligations:</strong>
                        <ul>
                            <li>If an event is canceled by the organizer, they must refund all ticket buyers in full within 5 business days.</li>
                            <li>Failure to issue refunds may result in penalties, including suspension from the platform.</li>
                        </ul>
                    </li>
                    <li style={styles.listItem}>
                        <strong>Force Majeure:</strong> For events canceled due to unforeseen circumstances, refunds will be handled per the organizer's posted policy.
                    </li>
                </ul>
            </Box>

            <Divider style={styles.divider} />

            <Box>
                <Typography variant="h6" style={styles.sectionTitle}>
                    <BlockIcon style={styles.icon} />
                    4. Prohibited Conduct
                </Typography>
                <ul>
                    <li style={styles.listItem}>Use the platform for scams, fraud, or unauthorized resale.</li>
                    <li style={styles.listItem}>Misrepresent event details or your authority to host events.</li>
                    <li style={styles.listItem}>Violate these terms may lead to account termination and legal consequences.</li>
                </ul>
            </Box>

            <Box mt={4} p={2} bgcolor="#f5f5f5" borderRadius={1}>
                <Typography style={{ color: secondaryColor }}>
                    By clicking "Agree," you confirm you've read, understood, and accept these terms.
                </Typography>
            </Box>
        </Paper>
    );
};

export default TermsConditions; 