import { Navigate, useNavigate } from 'react-router-dom';
import {create} from 'zustand';
import EventDetailService from './cards_detail_services';
import { toast } from 'react-hot-toast';
import { log } from '../../data/api/util_api';

const ModalPayementService = create((set,get) => ({
    show:false,
    loading:false,
    step1:true,
    step2:false,
    isCheckPayment:false,
    isMtnPayement: false,
    isOrangePayement: true,
    phoneNumber: '',
    phoneError: { error: false, message: '' },
    initModal:()=>{
        set({step1:true,step2:false,loading:false,show:false})
    },
    toogleShow:()=>{
        log("loader");
        
        set({ show: !get().show,step1:true,step2:false});
    },
    toogleIsCheckPayment:()=>{
        log("toogleIsCheckPayment");
        if(!get().isCheckPayment)
        set({ isCheckPayment: true,isMtnPayement:false,isOrangePayement:false});
    },
    toogleIsMtnPayement:()=>{
        log("toogleIsMtnPayement");
        if(!get().isMtnPayement)
        set({ isCheckPayment: false,isMtnPayement:true,isOrangePayement:false});
    },
    toogleIsOrangePayement:()=>{
        log("toogleIsOrangePayement");
        if(!get().isOrangePayement)
        set({ isCheckPayment: false,isMtnPayement:false,isOrangePayement:true});
    },
    toogleLoading:()=>{
        log("loader");
        set({ isCheckPayment: false,isMtnPayement:false,isOrangePayement:false});
        set({ loading: !get().loading});
        const timer = setTimeout(() => {
            set({ step1: false, step2:true,loading:false});
          }, 2000);

        return () => clearTimeout(timer);

    },
    switchStep:(step1,step2)=>{
        set({ step1: step1, step2:step2});
    },
    setPhoneNumber: (number) => {
        let error = { error: false, message: '' };
        
        // Remove any non-digit characters except +
        const formattedNumber = number.replace(/[^\d+]/g, '');
        
        // Validate phone number
        if (!formattedNumber) {
            error = { error: true, message: 'Phone number is required' };
        } else if (!/^\+237[6-9]\d{8}$/.test(formattedNumber)) {
            error = { error: true, message: 'Invalid Cameroon phone number' };
        }
        
        set({ phoneNumber: formattedNumber, phoneError: error });
    },
    isPaymentMethodValid: () => {
        const state = get();
        const { commenderData, errorCommenderData } = EventDetailService.getState();
        
        if (state.isCheckPayment) {
            return true; // Add bank card validation if needed
        } else if (state.isMtnPayement || state.isOrangePayement) {
            return commenderData.phoneNumber && !errorCommenderData.phoneNumber.activate;
        }
        return false;
    },
    initializeOrangePayment: () => {
        set({ 
            isCheckPayment: false,
            isMtnPayement: false,
            isOrangePayement: true 
        });
    },
    setLoading: (value) => {
        set({ loading: value });
    },
    handleBuyClick: async (handleRedirect) => {
        try {
            set({ loading: true });
            await handleRedirect();
        } catch (error) {
            console.error("Error during purchase:", error);
            toast.error("Failed to process purchase");
        } finally {
            set({ loading: false });
        }
    }
}));

export default ModalPayementService;