import React, { useEffect, useState } from 'react';
import Spinner from '../spinner';
import ModalPayementService from '../../domain/usecase/modal_payement_service';
import { Link, useNavigate } from 'react-router-dom';
import FormPayementBankCard from './form_payemrnt_bank_card';
import FormPayementMtnMM from './form_payement_mtn_mm';
import FormPayementOrangeMM from './form_payement_orange_mm';
import EventDetailService from '../../domain/usecase/cards_detail_services';
import ModalSubscription from '../modal_subscription_login/modal_subscription';
import MemberManagementService from '../../domain/usecase/member_management_service';
import InteractionVM from '../../view_model/interaction_vm';
import { InputAdornment, TextField } from '@mui/material';
import FormErrorFieldMessage from '../form_error_feild';
import { toast } from 'react-hot-toast';
import Step1Content from './Step1Content';
import Step2Content from './Step2Content';

const ModalPayement = () => {
  //const [show, setShow] = useState(false);

  //const handleClose = () => setShow(false);
  
  //const handleShow = () => setShow(true);

  const navigate = useNavigate();


  const { show, loading,toogleShow,step1,step2,isCheckPayment,isMtnPayement,isOrangePayement,toogleIsCheckPayment,
    toogleIsMtnPayement,toogleIsOrangePayement,switchStep,initModal, isPaymentMethodValid, handleBuyClick } = ModalPayementService();
   const {isconnected,initAccount,showLogin,toogleShowLogin,isLogin,resetTokenValid,isTokenValide,isSubscription} = MemberManagementService();
   const { listPricing,addPurshareTicket,subPurshareTicket,taxe,saveCommenderTicker,initErrorCommenderData,errorCommenderData,commenderData,setCommenderData,formValidation} = EventDetailService();

   const handleRedirect = async () => {
    try {
      await formValidation(isconnected);
      const savedTickets = await saveCommenderTicker(isconnected);
      
      if (savedTickets) {
        toogleShow();
        navigate("/member_tickets", { replace: true, state: { refresh: true } });
      }
    } catch (error) {
      console.error("Error during redirect:", error);
      toast.error("Failed to save tickets");
    }
  };

    useEffect(()=>{
        initModal();
        initAccount(); 
        initErrorCommenderData();
    },[resetTokenValid]);
  
    return (
        <>
        {showLogin&&<ModalSubscription show={showLogin} toogleShow={()=>toogleShowLogin(false)} isLogin={isLogin} isSubscription={isSubscription}/>}
          <button className="button-round" onClick={toogleShow} >
            Place Order
          </button>
    
          <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  {step1&&<h5 className="modal-title">Purchase your ticket</h5>}
                  {step2&&<h5 className="modal-title">Billing information</h5>}
                  <button type="button" className="close" aria-label="Close" onClick={toogleShow}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>  
                <div className="modal-body">
                    <main id="content" className="site-main">
                    {/*<!-- Inner Banner html start-->*/}
                    <div className="cart-section">
                    <div className="container">
                        <div className="cart-list-inner">
                            <form action="#">
                                <div className='row '>
                                    {step1&&<Step1Content 
                                      listPricing={listPricing}
                                      addPurshareTicket={addPurshareTicket}
                                      subPurshareTicket={subPurshareTicket}
                                    />}
                                    {step2&&<Step2Content 
                                      isTokenValide={isTokenValide}
                                      isconnected={isconnected}
                                      toogleShowLogin={toogleShowLogin}
                                      toogleShow={toogleShow}
                                      errorCommenderData={errorCommenderData}
                                      commenderData={commenderData}
                                      setCommenderData={setCommenderData}
                                      isCheckPayment={isCheckPayment}
                                      isMtnPayement={isMtnPayement}
                                      isOrangePayement={isOrangePayement}
                                      toogleIsCheckPayment={toogleIsCheckPayment}
                                      toogleIsMtnPayement={toogleIsMtnPayement}
                                      toogleIsOrangePayement={toogleIsOrangePayement}
                                    />}
                                    <div className='col col-md-4'>
                                        <div className="updateArea" style={{backgroundColor:"#263238"}}>
                                            {/*<div className="input-group-wrap">
                                                <input type="text" className="form-control" placeholder="I have a discount coupon"/>
                                                <a href="#" className="button-round mt-2">apply coupon</a>
                                            </div>*/}
                                        
                                        </div>
                                        <div className="totalAmountArea">
                                            <ul className="list-unstyled">
                                                <li><strong>Sub Total</strong> <span>XAF {listPricing.reduce((sum, item) => {
                                                        return sum + (item.purshareQuantity * item.cost);
                                                    }, 0.0)}</span></li>
                                                <li><strong>Tax</strong> <span>{taxe}</span></li>
                                                <li><strong>Grand Total</strong> <span className="grandTotal">XAF {listPricing.reduce((sum, item) => {
                                                        return sum + (item.purshareQuantity * item.cost) + ((item.purshareQuantity * item.cost) * taxe/100);
                                                    }, 0.0)}</span></li>
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className='row'>
                                {step2&&<div className="text-center col col-md-4">
                                            <div className="button-round " style={{backgroundColor:"#4f5b62",color:"#fff"}} onClick={()=>switchStep(true,false)}> Cancel</div>
                                            
                                    </div>}
                                    {step1&&listPricing.reduce((sum, item) => {
                                                        return sum + (item.purshareQuantity * item.cost);
                                                    }, 0.0)>0?<div className="text-center">
                                                    <div className="button-round" onClick={()=>switchStep(false,true)
                                                    }> Purchase</div>
                                                    
                                            </div>:step1&&<div className="text-center">
                                                    <div className="button-round" style={{
                                                        opacity: 0.5,
                                                        cursor: 'not-allowed'
                                                    }}> Purchase</div>
                                                    
                                            </div>}
                                    {step2&&<div className="text-center col col-md-4">
                                            {loading ? (
                                                <div className="button-round">
                                                    <Spinner/> Loading...
                                                </div>
                                            ) : (
                                                <div 
                                                    className={`button-round ${!isPaymentMethodValid() ? 'disabled' : ''}`}
                                                    onClick={() => isPaymentMethodValid() && handleBuyClick(handleRedirect)}
                                                    style={{
                                                        opacity: isPaymentMethodValid() ? 1 : 0.5,
                                                        cursor: isPaymentMethodValid() ? 'pointer' : 'not-allowed'
                                                    }}
                                                >
                                                    {loading ? (
                                                        <>
                                                            <Spinner/> Processing...
                                                        </>
                                                    ) : (
                                                        'Buy'
                                                    )}
                                                </div>
                                            )}
                                            
                                    </div>}
                                </div>
                            </form>
                        </div>
                    </div>
                    </div>
                </main>
                
                </div>
                
              </div>
            </div>
          </div>
          {show && <div className="modal-backdrop fade show"></div>}
        </>
      );
  
};

export default ModalPayement;
