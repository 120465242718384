import { log } from "../api/util_api";

export const environment  = {
    isRomote: true,
    localIp: "localhost",
    remoteIp: "deventdb.my-dservice.com",
    remotIpfs: "gateway.pinata.cloud", 
    port: 8297,
    ipfsPortGet:7072,
    ipfsPortSend: 7071,
};

export var baseUrl = (environment.isRomote ?"https":"http")+"://" + (environment.isRomote ? environment.remoteIp : environment.localIp) ;

///-------------------- remote URL for IPFS ----------------------
export var ipfsUrl = {
    IPFS_GET_IMAGE :(environment.isRomote ?"https":"http")+"://"+(environment.isRomote ? environment.remotIpfs : environment.localIp)+(environment.isRomote ?"":":"+environment.ipfsPortGet)+"/ipfs" ,
    IPFS_SEND_IMAGE : baseUrl+":"
}

///-------------------- remote URL for API call ------------------- 
export var serviceApi = {
    SAVE_CARD:  baseUrl+(environment.isRomote?"":":"+environment.port)+"/public/api/v1/de/card",
    GET_ALL_CARD_API : baseUrl+(environment.isRomote?"":":"+environment.port)+"/public/api/v1/de/card",
    SAVE_EVENT:  baseUrl+(environment.isRomote?"":":"+environment.port)+"/event", 
    SEND_EMAIL : baseUrl+(environment.isRomote?"":":"+environment.port)+"/api/v1/mail",
    GET_ALL_TAG_CATEGORY : baseUrl+(environment.isRomote?"":":"+environment.port)+"/public/tag/category",
    GET_ALL_EVENT : baseUrl+(environment.isRomote?"":":"+environment.port)+"/event/public",
    GET_SAVE_COMMANDER : baseUrl+(environment.isRomote?"":":"+environment.port)+"/commander/public/create",
    GET_TICKET_PDF_PRINTER : baseUrl+(environment.isRomote?"":":"+environment.port)+"/printer/public/event/ticket",
    GET_SAVE_COMMANDER_PRICING : baseUrl+(environment.isRomote?"":":"+environment.port)+"/commander-pricing",
    GET_ALL_EVENT_CATEGORY : baseUrl+(environment.isRomote?"":":"+environment.port)+"/event/public/by-tevent-category",
    GET_ALL_EVENT_DATE : baseUrl+(environment.isRomote?"":":"+environment.port)+"/event/public/by-date",
    GET_ALL_EVENT_PRICING : baseUrl+(environment.isRomote?"":":"+environment.port)+"/pricing/public/event",
    GET_DETAILS : baseUrl+(environment.isRomote?"":":"+environment.port)+"/event/public/details",
    GET_ALL_CATEGORY : baseUrl+(environment.isRomote?"":":"+environment.port)+"/public/event-category",
    ///campay request api
    REQUEST_PAYEMENT: baseUrl+(environment.isRomote?"":":"+environment.port)+"/api/v1/payments/initiate",
    GET_TOWN_COUNTRY : "https://nominatim.openstreetmap.org/reverse?format=json&",
    GET_LATlONG_API: "https://nominatim.openstreetmap.org/search",
    LOGIN_MEMBER : baseUrl+(environment.isRomote?"":":"+environment.port)+"/auth/login",
    SIGNUP_MEMBER : baseUrl+(environment.isRomote?"":":"+environment.port)+"/auth/signup",
    CHECK_TOKEN : baseUrl+(environment.isRomote?"":":"+environment.port)+"/auth/check_token",
    CREATE_ORGANISATION : baseUrl+(environment.isRomote?"":":"+environment.port)+"/public/organisation",
    CREAT_EVENT_PRICING : baseUrl+(environment.isRomote?"":":"+environment.port)+"/pricing",
    CREATE_COMMANDER: baseUrl+(environment.isRomote?"":":"+environment.port)+"/commander/create",
}

