import axios from "axios";
import { serviceApi } from "../../data/url";
import { log } from "../../data/api/util_api";

export const cardService = {
    saveCardApi,
    getAllCards,
    sendMail
}



// Custom Errors
class CustomError extends Error {
    constructor({ title, message, url }) {
      super(message);
      this.title = title;
      this.message = message;
      this.url = url;
    }
  }
//structure api error
class APIError extends Error {
    constructor({ message, data, statusCode }) {
        super(message);
        this.name = "APIError";
        this.data = data;
        this.statusCode = statusCode;
    }
}

function messageError(BackendError) {
    return BackendError.detail != null ? BackendError.detail : BackendError.title;
  }

function handleAPIError(err, url) {
    switch (err.statusCode) {
      case 400:
        return new CustomError({
          title: "Bad Request",
          message: messageError(err.data),
          url,
        });
      case 401:
      case 403:
        return new CustomError({
          title: "Unauthorized",
          message: messageError(err.data),
          url,
        });
      case 404:
        return new CustomError({
          title: "Not Found",
          message: messageError(err.data),
          url,
        });
      case 500:
      default:
        return new CustomError({
          title: "Server Error",
          message:
            err.data != null ? messageError(err.data) : "Server Unavailable",
          url,
        });
    }
  }

  function handleAxiosError(err, url) {
    if (err.isAxiosError) {
      if (err.response) {
        return handleAPIError(
          new APIError({
            message: "API Error Detected",
            data: err.response.data,
            statusCode: err.response.status,
          })
        );
      } else {
        return new CustomError({
          title: "NetworkError",
          message: "Network Error!",
          url,
        });
      }
    } else {
      throw err;
    }
  }

async function saveCardApi (params) {
    
    try {
        log("-- saveCardApi --");
        log(params);
        return await axios.post(serviceApi.SAVE_CARD, params);
      } catch (err) {
        log("-- error --" + err);
        return handleAxiosError(err, serviceApi.SAVE_CARD);
      }
}

async  function getAllCards () {
    try{
        return (await axios.get(serviceApi.GET_ALL_CARD_API)).data;
    }catch(err){
        return handleAxiosError(err, serviceApi.GET_ALL_CARD_API);
    }
}

async function sendMail (params) {
    
  try {
      await axios.post(serviceApi.SEND_EMAIL, params);
      return "success";
    } catch (err) {
      log("-- error --" + err);
      return "error";
      //return handleAxiosError(err, baseurl + "api/v1/mail");
    }
}