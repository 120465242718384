import React, { Fragment, useEffect, useState } from "react";
import DownloadApp from "../components/download_app";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";

const EventTicket = ()=>{
    const [day,setDay] = useState(0);
    const [hour,setHour] = useState(0);
    const [min,setMin] = useState(0);
    const [sec,setSec] = useState(0);
    const [textIndex, setTextIndex] = useState(0);

    const texts = [
        'Event Ticket!', 
        'Travel Ticket!', 
        'Forum Ticket!', 
        'Concert Ticket!'
    ];

    const {t} = useTranslation(['event_ticket','common']);

    useEffect(() => {
        const interval = setInterval(() => {
            setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 2000);
        
        return () => clearInterval(interval);
    }, []);

    useEffect(()=>{
        // Set target date to 14 days from now
        const now = new Date();
        const target = new Date(now.getTime() + (14 * 24 * 60 * 60 * 1000));
        
        const interval = setInterval(()=>{
            const now = new Date();
            const diff = target.getTime() - now.getTime();

            const d = Math.floor(diff/(1000*60*60*24));
            setDay(d);
            const h = Math.floor((diff%(1000*60*60*24))/(1000*60*60));
            setHour(h);
            const m = Math.floor((diff%(1000*60*60))/(1000*60));
            setMin(m);
            const s = Math.floor((diff%(1000*60))/1000);
            setSec(s);

        },1000);

        return ()=>clearInterval(interval);
    },[]);

    return (
        <Fragment>
            <main id="content" className="site-main">
                {/*<!-- Inner Banner html start-->*/}
                <section className="inner-banner-wrap">
                    <div className="inner-baner-container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="container">
                                    <div className="inner-banner-content">
                                        <h2 className="inner-title">{t('HEADER_TITLE')}</h2><br/>
                                        <h4 className="inner-title">{t('HEADER_TITLE_P')}</h4><br/>
                                        
                                        {/* Happy Animation */}
                                        <div className="px-2 sm:px-2 md:px-3 bg-[#D1711F] text-black overflow-hidden py-0.5 sm:py-1 md:py-2 justify-center rounded-[15px]">
                                            <AnimatePresence mode='wait'>
                                                <motion.div
                                                    key={textIndex}
                                                    initial={{ y: "100%" }}
                                                    animate={{ y: 0 }}
                                                    exit={{ y: "-120%" }}
                                                    transition={{ 
                                                        type: "spring", 
                                                        damping: 30, 
                                                        stiffness: 400 
                                                    }}
                                                    style={{
                                                        display: 'block',
                                                        textAlign: 'center',
                                                        fontWeight: '600',
                                                        fontSize: '1.5em',
                                                        color: 'white',
                                                        textShadow: '1px 1px 2px rgba(0,0,0,0.1)'
                                                    }}
                                                >
                                                    {texts[textIndex]}
                                                </motion.div>
                                            </AnimatePresence>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <figure className="banner-image">
                                    <img src="assets/images/header2.png" alt=""/>
                                </figure>
                            </div>
                        </div>
                        
                        <div className="overlay-image"></div>
                        <div className="bottom-shape">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                                <path className="elementor-shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                                c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                                c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
                            </svg>
                        </div>
                    </div>
                </section>
                {/*<!-- Inner Banner html end-->*/}
                {/*<!-- TComming soon section -->*/}
                <div className="comming-soon-section">
                <div className="container">
                    <div className="comming-soon-wrap">

                    <article className="comming-soon-content">
                        <h2>{t('HEADER_CONTENT')} !!</h2>
                        <h4>{t('HEADER_CONTENT_P')}</h4>
                    </article>
                    
                    <div className="time-counter-wrap">
                        <div className="time-counter" data-date="2024-12-01 23:58:58">
                            
                            <div className="counter-time">
                                <span className="counter-days">{day}</span> 
                                <span className="label-text">{t('DAY')}</span>
                            </div>
                            <div className="counter-time">
                                <span className="counter-hours">{hour}</span> 
                                <span className="label-text">{t('HOUR')}</span>
                            </div>
                            <div className="counter-time">
                                <span className="counter-minutes">{min}</span> 
                                <span className="label-text">{t('MIN')}</span>
                            </div>
                            <div className="counter-time">
                                <span className="counter-seconds">{sec}</span> 
                                <span className="label-text">{t('SEC')}</span>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
         </div>
                {/*<!-- Team html end -->*/}
                <section className="blog-section">
                    <DownloadApp isOverlay={true}/>
                </section>
            </main>
        </Fragment>
    );
}

export default EventTicket;

