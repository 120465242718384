import { Link } from "react-router-dom";
import MemberManagementService from "../../domain/usecase/member_management_service";
//import Loader from "../loader";
import { InputAdornment, TextField, IconButton } from "@mui/material";
import { log } from "../../data/api/util_api";
import { useState } from "react";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';

const Login = ()=>{
    const [showPassword, setShowPassword] = useState(false);
    const {login, forLoginValidation, isLicenceCheck, toogleLicencecheck, toogleActiveSubscription, loading, email, pwd, errorMemberData, setMemberData, isErrorFound} = MemberManagementService();

    const handleClick = (e) => {
        e.preventDefault(); 
        window.open("/term_condition", "_blank");
    };

    return(
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="contact-from-wrap">
                        <div className="section-heading text-center">
                            <div className="sub-title">WELCOME BACK</div>
                            <h2 className="section-title">Login</h2> 
                        </div>
                        <div className="contact-form">
                            <p>   
                                <TextField 
                                    id="outlined1" 
                                    error={errorMemberData.email.activate} 
                                    helperText={errorMemberData.email.message} 
                                    onChange={(e)=>{ setMemberData("email",e.target.value); }} 
                                    value={email} 
                                    label="Your Email Address" 
                                    placeholder="Enter your E-mail" 
                                    sx={{ minWidth: "100%" }}    
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon />
                                            </InputAdornment>
                                        ),
                                    }} 
                                />
                            </p>
                            
                            <p>   
                                <TextField  
                                    type={showPassword ? 'text' : 'password'}
                                    id="outlined2" 
                                    error={errorMemberData.pwd.activate} 
                                    helperText={errorMemberData.pwd.message} 
                                    onChange={(e)=>{ setMemberData("pwd",e.target.value); }} 
                                    value={pwd} 
                                    label="Password" 
                                    placeholder="Enter your Password" 
                                    sx={{ minWidth: "100%" }}    
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="fas fa-lock" />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }} 
                                />
                            </p>

                            <div className='row'>
                                <div className='col-md-6 d-flex justify-content-start'>
                                    <p className="mb-0"> 
                                        <label className="d-flex align-items-center">
                                            <input type="checkbox" name="licence" checked={isLicenceCheck} onChange={toogleLicencecheck} /> 
                                            <span className="ms-2">Accept <Link to="#" onClick={handleClick} className="text-primary">Terms & Conditions</Link></span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col-md-6 d-flex justify-content-end'>
                                    <p className="mb-0"> 
                                        <Link to="#" onClick={handleClick} className="text-primary">Forgot password?</Link>
                                    </p>
                                </div>
                            </div>
                            
                            <button 
                                onClick={()=>{
                                    forLoginValidation();
                                    if(isLicenceCheck && !isErrorFound){
                                        login();
                                    }
                                }}  
                                className="button-round col-12 mt-4"
                                disabled={loading}
                            >
                                {loading ? 'Processing...' : 'Login'}
                            </button>

                            <div className='col d-flex justify-content-center mt-3'>
                                <p className="mb-0">
                                    Don't have an account? <Link to="#" onClick={toogleActiveSubscription} className="text-primary">Subscribe</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;