import toast from "react-hot-toast";
import { saveEvents } from "../../data/api/event_api";
import { creatEventPricing } from "../../data/api/event_pricing_api";
import { signup } from "../../data/api/member";
import { createOrganisation } from "../../data/api/organisation";
import { createCommander, createCommanderPricing, createTicketPdf } from "../../data/api/commender_api";
import EventPricingModel from "../model/event_pricing_model";
import { requestPayApi } from "../../data/api/campay_pay_api";
import { log } from "../../data/api/util_api";



const createMemberOrganisationService = async (eventData) => {

  log("--eventData--",eventData);
  ///Create organisation with member id
  return await createOrganisation(eventData).then(async (org)=>{
    /// Create member account with admin role
    if(org.sucess && org.data.id!==null){
      const member = await signup(eventData,org.data.id);
      return member;
    }
    return null;
  });
};

const createEventService = async (eventData,key,pricings,navigate)=>{
  
  ///create event
  saveEvents(eventData,key).then(async (event)=>{
    
    ///create pricing
    if(event.sucess){
      for (const element of pricings) {
        toast.success("Event  create with success");
        var pricing = await creatEventPricing(element,event,key);
        if(pricing.success){
          pricing = new EventPricingModel(pricing.data);
          
          toast.success("Pricing  create with success");
        }else{
          toast.error("Pricing  create with success");
        }
        
        // Handle response if needed
      }
     
      navigate(`/event_detail/${event.data.id}`);
      
    }else{
      toast.error(event);
    }
    
  });
  
}

 const createCommenderTicketService = async (commanderData, listPricing,phoneNumber,totalAmount) => {
  try {

    log("********** phoneNumber *********** "+phoneNumber)
    
    ///start transaction with campay
    const payResponse = await requestPayApi(phoneNumber,totalAmount);
    log("*** payResponse ****",payResponse);
    if (!payResponse.success) {
      toast.error("Erreur lors de de latransaction avec campay, "+payResponse.exception.message);
      return [];
    }else{
      toast.success("Payement initiate with success please type "+payResponse.data.ussd_code+" to confirm")
    }
      
    // Créer le commander
    const commanderResponse = await createCommander(commanderData);
    if (!commanderResponse.success) {
      toast.error("Erreur lors de la création du commander");
      return [];
    }

    const commanderId = commanderResponse.data.id;
    let tickets = [];

    // Créer des tickets pour chaque élément de la liste de prix
    for (const pricing of listPricing) {
      for (let i = 0; i < pricing.purshareQuantity; i++) {
        const ticketResponse = await createCommanderPricing({
          payMethode: "MTN", // ou autre méthode de paiement
          numberPlace: 1,
          pricing: pricing.cost,
          commanderId: commanderId,
          pricingId: pricing.id
        });

        if (ticketResponse.success) {
          toast.success(`Ticket pour ${pricing.priceCategorie} créé avec succès`);
          const ticketPdfResponse = await createTicketPdf(ticketResponse.data.id);

          if (ticketPdfResponse.success) {
            tickets.push(ticketPdfResponse.data);
          } else {
            toast.error("Erreur lors de la création du PDF du ticket");
          }
        } else {
          toast.error("Erreur lors de la création du ticket");
        }
      }
    }

    return tickets;
  } catch (error) {
    console.error("Erreur lors de la création des tickets:", error);
    toast.error("Erreur lors de la création des tickets");
    return [];
  }
};

export {createMemberOrganisationService,createEventService,createCommenderTicketService};