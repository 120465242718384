import {serviceApi } from '../url';
import {request} from '../globalAxios';
import RequestResponse from '../../domain/model/request_responce';
import PayResponse from '../../domain/model/payResponse';

const requestPayApi = async (phoneNumber,amount) => {
  const response =  await request('post', serviceApi.REQUEST_PAYEMENT,{
    "amount": amount>100?5:2,
    "from": phoneNumber,
    "description": "D.Event payement from "+phoneNumber,
    "currency": "XAF"
  },);
  return new RequestResponse(new PayResponse(response.data),response.sucess,response.pageSize,response.page,response.exception);
};

export {requestPayApi};