import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import WelcomePage from "./page/welcome_page";
import WeddingCards from "./page/wedding_card";
import ContactUs from "./page/contact_us";
import AboutUs from "./page/about_us";
import Header from "./components/header";
import Footer from "./components/footer";
import './i18n'
import MainTicketEvent from "./page/main_ticket_event";
import SinglePageLayout from "./page/layout/single_page_layout";
import FilterEvent from "./page/filter_event";
import EventDetails from "./page/event_details";
import ScrollToTop from "./components/scrollToTop";
import CreateEvent from "./page/create_event";
import EventPreview from "./page/event_preview";
import { Toaster } from "react-hot-toast";
import Licence from "./page/licence";
import Ticket from "./page/tickets/member_tickets";
import MinimalisticTicket from "./page/tickets/minimalistic_ticket";
import VerticalTicket from "./page/tickets/vertical_ticket";
import HorizontalTicket from "./page/tickets/horizontal_ticket";
import CinemaTicket from "./page/tickets/ticket_model1";
import TicketItem from "./components/ticket/ticket_item";
import MyTickets from "./page/my_tickets";
import AdminDashboard from './page/admin';
import EventTicket from "./page/event_ticket";
import MainTermsConditions from "./page/main_term_condition";

function App() {
  const location = useLocation();
  const isAdminRoute = location.pathname === '/admin';

  return (
    <>
      {/*<Loader/>*/}
     
      <div id="page" className="full-page">
        <ScrollToTop />
        {!isAdminRoute && <Header />}
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/weddind_cards" element={<WeddingCards />} />
          <Route path="/term_condition" element={<MainTermsConditions />} />
          <Route path="/event_tickets" element={<MainTicketEvent />} />
          <Route path="/event_tickets_soon" element={<EventTicket />} />
          <Route path="/contact_us" element={<ContactUs />} />
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/event_detail/:id" element={<EventDetails />} />
          <Route path="/event_preview" element={<EventPreview />} />
          <Route path="/licence" element={<Licence />} />
          <Route path="/member_tickets" element={<MyTickets />} />
          <Route path="/member_tickets1" element={<MinimalisticTicket />} />
          <Route path="/member_tickets2" element={<VerticalTicket />} />
          <Route path="/member_tickets3" element={<HorizontalTicket />} />
          <Route path="/member_tickets4" element={<CinemaTicket />} />
          <Route path="/" element={<SinglePageLayout />} >
            <Route path="/filter_event/:filterKey" element={<FilterEvent />} />
            <Route path="/create_event/step1/:filterKey" element={<CreateEvent />} />
          </Route>
          <Route path="/admin" element={<AdminDashboard />} />
        </Routes>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
        {!isAdminRoute && <Footer />}
      </div>
    </>
  );
}

export default App;
