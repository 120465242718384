import React from 'react';
import { Link } from 'react-router-dom';

const Step1Content = ({ listPricing, addPurshareTicket, subPurshareTicket }) => {
  return (
    <div className='col col-md-8'>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th style={{color:"#fff",backgroundColor:"#263238"}}>Label</th>
              <th style={{color:"#fff",backgroundColor:"#263238"}}>Price</th>
              <th style={{color:"#fff",backgroundColor:"#263238"}}>Quantity</th>
              <th style={{color:"#fff",backgroundColor:"#263238"}}>Sub Total</th>
            </tr>
          </thead>
          <tbody>
            {listPricing.map((p, index) => (
              <tr key={index}>
                <td data-column="Product Name">{p.priceCategorie}</td>
                <td data-column="Price">XAF {p.cost}</td>
                <td data-column="Quantity">
                  <div className="post-navigation-wrap">
                    <nav>
                      <ul className="pagination">
                        <li>
                          <Link
                            to="#"
                            onClick={(event) => {
                              event.preventDefault();
                              subPurshareTicket(index);
                            }}
                            className={'disabled'}
                          >
                            <i className="fas fa-minus"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#" style={{fontSize:"15pt"}}>{p.purshareQuantity}</Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            onClick={(event) => {
                              event.preventDefault();
                              addPurshareTicket(index);
                            }}
                            className={'disabled'}
                          >
                            <i className="fas fa-plus"></i>
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </td>
                <td data-column="Sub Total">XAF {(p.cost * p.purshareQuantity)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Step1Content; 