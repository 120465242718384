import { Link } from "react-router-dom";
import MemberManagementService from "../../domain/usecase/member_management_service";
import ImageLogo from "../upload_log";
import { InputAdornment, TextField, IconButton } from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import BusinessIcon from '@mui/icons-material/Business';
import DescriptionIcon from '@mui/icons-material/Description';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LanguageIcon from '@mui/icons-material/Language';
import { log } from "../../data/api/util_api";

const Signup = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfPassword, setShowConfPassword] = useState(false);

    const { signin, setMemberData, isLicenceCheck, toogleLicencecheck, toogleActiveSubscription, loading, memberData, errorMemberData, isErrorFound, formValidation, initErrorMemberData } = MemberManagementService();

    const handleClick = (e) => {
        e.preventDefault(); // Prevent the default behavior
        window.open("/licence", "_blank"); // Open the link in a new tab
    };

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="contact-from-wrap">
                        <div className="section-heading">
                            <div className="sub-title">Create your account and enjoy our services</div>
                            <h2 className="section-title">Subscription</h2>                            
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="contact-form">
                                    <p>
                                        <label>Enter member details </label>
                                        <TextField 
                                            id="outlined" 
                                            error={errorMemberData.name.activate} 
                                            helperText={errorMemberData.name.message} 
                                            onChange={(e)=>{ setMemberData("name",e.target.value); }} 
                                            value={memberData.name} 
                                            label="Name" 
                                            placeholder="Name" 
                                            sx={{ minWidth: "100%" }}    
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PersonIcon />
                                                    </InputAdornment>
                                                ),
                                            }} 
                                        />
                                    </p>
                                    <p>
                                        <TextField 
                                            id="outlined" 
                                            error={errorMemberData.subemail.activate} 
                                            helperText={errorMemberData.subemail.message} 
                                            onChange={(e)=>{ setMemberData("subemail",e.target.value); }} 
                                            value={memberData.subemail} 
                                            label="Email" 
                                            placeholder="Email" 
                                            sx={{ minWidth: "100%" }}    
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <EmailIcon />
                                                    </InputAdornment>
                                                ),
                                            }} 
                                        />
                                    </p>
                                    <p>
                                        <TextField 
                                            id="outlined" 
                                            error={errorMemberData.whatsappnumber.activate} 
                                            helperText={errorMemberData.whatsappnumber.message} 
                                            type="number" 
                                            onChange={(e)=>{ setMemberData("whatsappnumber",e.target.value); }} 
                                            value={memberData.whatsappnumber} 
                                            label="WhatsApp Number" 
                                            placeholder="WhatsApp Number" 
                                            sx={{ minWidth: "100%" }}    
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <WhatsAppIcon />
                                                    </InputAdornment>
                                                ),
                                            }} 
                                        />
                                    </p>
                                    <p>
                                        <TextField 
                                            id="outlined" 
                                            error={errorMemberData.phonenumber.activate} 
                                            helperText={errorMemberData.phonenumber.message} 
                                            type="number" 
                                            onChange={(e)=>{ setMemberData("phonenumber",e.target.value); }} 
                                            value={memberData.phonenumber} 
                                            label="Phone Number" 
                                            placeholder="Phone Number" 
                                            sx={{ minWidth: "100%" }}    
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PhoneIcon />
                                                    </InputAdornment>
                                                ),
                                            }} 
                                        />
                                    </p>
                                    <p>
                                        <TextField 
                                            id="outlined" 
                                            error={errorMemberData.password.activate} 
                                            helperText={errorMemberData.password.message} 
                                            type={showPassword ? 'text' : 'password'}
                                            onChange={(e)=>{ setMemberData("password",e.target.value); }} 
                                            value={memberData.password} 
                                            label="Password" 
                                            placeholder="Password" 
                                            sx={{ minWidth: "100%" }}    
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <i className="fas fa-lock" />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }} 
                                        />
                                    </p>
                                    <p>
                                        <TextField 
                                            id="outlined" 
                                            error={errorMemberData.confpassword.activate} 
                                            helperText={errorMemberData.confpassword.message} 
                                            type={showConfPassword ? 'text' : 'password'}
                                            onChange={(e)=>{ setMemberData("confpassword",e.target.value); }} 
                                            value={memberData.confpassword} 
                                            label="Confirm Password" 
                                            placeholder="Confirm Password" 
                                            sx={{ minWidth: "100%" }}    
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <i className="fas fa-lock" />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowConfPassword(!showConfPassword)}
                                                            edge="end"
                                                        >
                                                            {showConfPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }} 
                                        />
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="contact-form">
                                    <p>
                                        <label>Enter organisation details </label>
                                        <TextField 
                                            id="outlined" 
                                            error={errorMemberData.organisation.orgname.activate} 
                                            helperText={errorMemberData.organisation.orgname.message} 
                                            onChange={(e)=>{ setMemberData("orgname",e.target.value); }} 
                                            value={memberData.organisation.orgname} 
                                            label="Organisation Name" 
                                            placeholder="Organisation Name" 
                                            sx={{ minWidth: "100%" }}    
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <BusinessIcon />
                                                    </InputAdornment>
                                                ),
                                            }} 
                                        />
                                    </p>
                                    <p>
                                        <TextField 
                                            id="outlined" 
                                            error={errorMemberData.organisation.orgdescription.activate} 
                                            helperText={errorMemberData.organisation.orgdescription.message} 
                                            onChange={(e)=>{ setMemberData("orgdescription",e.target.value); }} 
                                            value={memberData.organisation.orgdescription} 
                                            label="Organisation Description" 
                                            placeholder="Organisation Description" 
                                            sx={{ minWidth: "100%" }}    
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <DescriptionIcon />
                                                    </InputAdornment>
                                                ),
                                            }} 
                                        />
                                    </p>
                                    <p>
                                        <TextField 
                                            id="outlined" 
                                            onChange={(e)=>{ setMemberData("facebooklink",e.target.value); }} 
                                            value={memberData.facebooklink} 
                                            label="Facebook Link" 
                                            placeholder="Facebook Link" 
                                            sx={{ minWidth: "100%" }}    
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <FacebookIcon />
                                                    </InputAdornment>
                                                ),
                                            }} 
                                        />
                                    </p>
                                    <p>
                                        <TextField 
                                            id="outlined" 
                                            onChange={(e)=>{ setMemberData("linkedinlink",e.target.value); }} 
                                            value={memberData.linkedinlink} 
                                            label="LinkedIn Link" 
                                            placeholder="LinkedIn Link" 
                                            sx={{ minWidth: "100%" }}    
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LinkedInIcon />
                                                    </InputAdornment>
                                                ),
                                            }} 
                                        />
                                    </p>
                                    <p>
                                        <TextField 
                                            id="outlined" 
                                            onChange={(e)=>{ setMemberData("websitelink",e.target.value); }} 
                                            value={memberData.websitelink} 
                                            label="Website Link" 
                                            placeholder="Website Link" 
                                            sx={{ minWidth: "100%" }}    
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LanguageIcon />
                                                    </InputAdornment>
                                                ),
                                            }} 
                                        />
                                    </p>
                                    <p>
                                        <ImageLogo title={"Logo"} imageKey="Organisationlogo" />
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 d-flex justify-content-start'>
                                    <p> 
                                        <label>
                                            <input type="checkbox" name="licence" checked={isLicenceCheck} onChange={toogleLicencecheck} /> 
                                            &nbsp;By subscribing you agry with all our <Link to="/licence" onClick={handleClick} >Conditions and rules</Link>.
                                        </label>
                                    </p>
                                </div>
                            </div>

                                    {false?<button className="button-round col-12 mt-2" >Loading ...</button>:<button onClick={()=>{
                                        initErrorMemberData();
                                        formValidation();
                                        setMemberData("rolename","Admin"); 
                                    //call subscription service
                                    if(isLicenceCheck){
                                        log(isErrorFound)
                                        if(!isErrorFound){
                                            signin(); 
                                        }
                                            
                                    }
                                        
                                    }}  className="button-round col-12 mt-2">Signup</button>
                                    }
                                    <div className='col d-flex justify-content-center mt-2'>
                                    <label>
                                            &nbsp;You have an account?  <Link to="#" onClick={toogleActiveSubscription} >Login</Link>.
                                        </label>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Signup;