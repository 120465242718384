import React from 'react';
import { TextField } from '@mui/material';
import FormErrorFieldMessage from '../form_error_feild';
import EventDetailService from '../../domain/usecase/cards_detail_services';
import { log } from '../../data/api/util_api';

const FormPayementMtnMM = () => {
    const { setCommenderData, commenderData, errorCommenderData } = EventDetailService();

    const handlePhoneChange = (e) => {
        let value = e.target.value;
        log('Original input:', value); // Debug log
        
        // Remove any non-digit characters
        value = value.replace(/\D/g, '');
        log('After removing non-digits:', value); // Debug log
        
        // Only add 237 if it's not already there
        if (value && !value.startsWith('237')) {
            value = '237' + value;
        }
        log('Final value:', value); // Debug log
        
        // Limit total length to 12 (237 + 9 digits)
        value = value.slice(0, 12);
        
        setCommenderData("phoneNumber", value);
    };

    return (
        <div className='row mt-2'>
            <div className="col-12">
                <div className="form-group">
                    {errorCommenderData.phoneNumber.activate && 
                        <FormErrorFieldMessage message={errorCommenderData.phoneNumber.message} />
                    }
                    <TextField
                        fullWidth
                        label="Phone Number *"
                        value={commenderData.phoneNumber}
                        onChange={handlePhoneChange}
                        error={errorCommenderData.phoneNumber.activate}
                        placeholder="237696871880"
                        inputProps={{
                            maxLength: 12
                        }}
                        helperText="Format: 237696871880"
                    />
                </div>
            </div>
        </div>
    );
};

export default FormPayementMtnMM;