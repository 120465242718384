// src/store/useDataStore.js
import {create} from 'zustand';
import { login } from '../../data/api/member';
import { createMemberOrganisationService } from '../service/eventService';
import toast from 'react-hot-toast';
import { isTokenValide } from '../service/authService';
import { createJSONStorage, persist } from 'zustand/middleware';
import { log } from '../../data/api/util_api';

const MemberManagementService = create(persist(
  (set, get) => ({
    isSubscription: false,
    isLogin: true,
    showLogin: false,
    email: "",
    pwd: "",
    loading:false,
    isLicenceCheck: true,
    isSubscriptionActivate: false,
    logo:"",
    errorLogo:"",
    isErrorFound: false,
    token: "",
    timer: 0,
    expiresIn:"",
    username: "",
    name:"",
    isTokenValide:false,
    saveToken:(value)=>{
      set({token:value});
      localStorage.setItem("key",value);
    },
    resetTokenValid: async () => {
      
      const resp = await isTokenValide();
      set({isTokenValide:resp});
    },
    initAccount:()=>{
      ///get login token if exist
      get().resetTokenValid();
      const key = localStorage.getItem("key")??"";
      const timer = localStorage.getItem("timer")??"";
      const username = localStorage.getItem("username")??"";
      const name = localStorage.getItem("name")??"";
      if (key!==null&&key!==""&&username!==null&&username!==""){ 
        set({token:key, isconnected:true, timer:timer , username:username , name:name});
        //log("key: "+get().token+" - timer: "+get().timer+" - username:"+get().username??"null"); 
      }else{
        set({showLogin:false, isSubscription: false,
          isLogin: true,isconnected:false});
      }
    },
    memberData:{
      name:"",
      subemail:"",
      whatsappnumber:"",
      phonenumber: "",
      password: "",
      confpassword: "",
      organisation: {
        orgname: "",
        orgdescription:"",
        facebooklink:"",
        linkedinlink: "",
        websitelink:"",
        logo: ""
      },
      organisationid: null,
      rolename: ""
    },
    errorMemberData:{
      email:{
        activate:false,
        message:""
      },
      pwd:{
        activate:false,
        message:""
      },
      name:{
        activate:false,
        message:""
      },
      subemail:{
        activate:false,
        message:""
      },
      whatsappnumber:{
        activate:false,
        message:""
      },
      phonenumber: {
        activate:false,
        message:""
      },
      password: {
        activate:false,
        message:""
      },
      confpassword: {
        activate:false,
        message:""
      },
      organisation: {
        orgname: {
          activate:false,
          message:""
        },
        orgdescription: {
          activate:false,
          message:""
        },
        facebooklink:{
          activate:false,
          message:""
        },
        linkedinlink: {
          activate:false,
          message:""
        },
        websitelink:{
          activate:false,
          message:""
        },
        logo: {
          activate:false,
          message:""
        }
      },
      organisationid: null,
      rolename: ""
    },
    initErrorMemberData:()=>{
      set({isErrorFound:false});
      set({errorMemberData:{
        email:{
          activate:false,
          message:""
        },
        pwd:{
          activate:false,
          message:""
        },
        name:{
          activate:false,
          message:""
        },
        subemail:{
          activate:false,
          message:""
        },
        whatsappnumber:{
          activate:false,
          message:""
        },
        phonenumber: {
          activate:false,
          message:""
        },
        password: {
          activate:false,
          message:""
        },
        confpassword: {
          activate:false,
          message:""
        },
        organisation: {
          orgname: {
            activate:false,
            message:""
          },
          orgdescription: {
            activate:false,
            message:""
          },
          facebooklink:{
            activate:false,
            message:""
          },
          linkedinlink: {
            activate:false,
            message:""
          },
          websitelink:{
            activate:false,
            message:""
          },
          logo: {
            activate:false,
            message:""
          }
        },
        organisationid: null,
        rolename: ""
      }})
    },
    setLogo:(path)=>{
      set({logo:path})
    },
    toogleActiveSubscription:()=>{
      set({isSubscriptionActivate: !get().isSubscriptionActivate})
    },
    toogleLicencecheck: ()=>{
      set({isLicenceCheck: !get().isLicenceCheck});
    },
    toogleShowLogin: (value)=>{
      set({showLogin:value, isSubscription: false, email:"",pwd:"",
        isLogin: true,});
        get().initErrorMemberData();
    },
    //token: 'YOUR_AUTH_TOKEN', // Add your token here or fetch it from a secure store
    login: async () => {
      set({loading:true});
      var response = await login({email:get().email,password:get().pwd}); 
        
      if(response!==null&&response.sucess){
        set({token:response.data.token, isconnected:true,isTokenValide:true, timer:response.data.expiresIn,name:response.data.name});
        localStorage.setItem("key",response.data.token);
        localStorage.setItem("timer",response.data.expiresIn);
        localStorage.setItem("username",response.data.username);
        localStorage.setItem("name",response.data.name);
        localStorage.setItem("publicKey",response.data.publicKey);
        //get().initAccount();
        set({showLogin:false})
        set({token:response.data.token, isconnected:true,isTokenValide:true, timer:response.data.expiresIn , username:response.data.username , name:response.data.username});
      }else{
        set({token:"", isconnected:false, timer:0});
      }
      
      set({loading:false}); 
    },
    logout:()=>{ 
      localStorage.removeItem('key')
      localStorage.removeItem('timer')
      localStorage.removeItem('username')
      localStorage.removeItem('name')
      localStorage.removeItem('publicKey')
      get().initAccount();
    },
    signin:async ()=>{
      set({loading: true});
      try{
      var resp =await createMemberOrganisationService(get().memberData);
      if(resp.sucess){
        toast.success("Congratulation, your account have been creat, please Login");
        set({isSubscriptionActivate: false})
      }
      set({loading: false});
      }catch (e){
        log(e);
        set({loading: false});
      }
      
      
    },
    setMemberData:(key,value)=>{
      const newMemeberData = get().memberData;
      switch(key){
        case "email": set({email:value}); break;
        case "pwd": set({pwd:value});  break;
        case "name": newMemeberData.name = value;  break;
        case "subemail": newMemeberData.subemail = value;  break;
        case "whatsappnumber": newMemeberData.whatsappnumber = value;  break;
        case "phonenumber": newMemeberData.phonenumber = value;  break;
        case "rolename": newMemeberData.rolename = value; break;
        case "password": newMemeberData.password = value;  break;
        case "confpassword": newMemeberData.confpassword = value; break;
        case "orgname": newMemeberData.organisation.orgname = value;  break;
        case "orgdescription": newMemeberData.organisation.orgdescription = value;  break;
        case "facebooklink": newMemeberData.organisation.facebooklink = value; break;
        case "linkedinlink": newMemeberData.organisation.linkedinlink = value; break;
        case "websitelink": newMemeberData.organisation.websitelink = value;  break;
        case "logo": newMemeberData.organisation.logo = value; break;
        default: log("************** unkonw key *****************");
      }
      set({memberData: newMemeberData});
    },
    forLoginValidation:()=>{
      const newErrorMemberData = get().errorMemberData;
      const email = get().email;
      const pwd = get().pwd;
      set({isErrorFound:false});
      if(email===""){
        newErrorMemberData.email.activate = true;
        newErrorMemberData.email.message = "Member name require *";
        set({isErrorFound:true});
      }
      if(pwd===""){
        newErrorMemberData.pwd.activate = true;
        newErrorMemberData.pwd.message = "Member e-mail require *";
        set({isErrorFound:true});
      }
  
  
    },
    formValidation:()=>{
      const memberData = get().memberData;
      const newErrorMemberData = get().errorMemberData;
      
      if(memberData.name===""){
        newErrorMemberData.name.activate = true;
        newErrorMemberData.name.message = "Member name require *";
        set({isErrorFound:true});
      }
      if(memberData.subemail===""){
        newErrorMemberData.subemail.activate = true;
        newErrorMemberData.subemail.message = "Member e-mail require *";
        set({isErrorFound:true});
      }
      if(memberData.whatsappnumber===""){
        newErrorMemberData.whatsappnumber.activate = true;
        newErrorMemberData.whatsappnumber.message = "Member whatsapp Number require *";
        set({isErrorFound:true});
      }
      if(memberData.password===""){
        newErrorMemberData.password.activate = true;
        newErrorMemberData.password.message = "Member password require *";
        set({isErrorFound:true});
      }
      if(memberData.password!==memberData.confpassword){
        newErrorMemberData.confpassword.activate = true;
        newErrorMemberData.confpassword.message = "Password are not the same *";
        set({isErrorFound:true});
      }
      if(memberData.organisation.orgname===""){
        newErrorMemberData.organisation.orgname.activate = true;
        newErrorMemberData.organisation.orgname.message = "Organisation name require *";
        set({isErrorFound:true});
      }
      if(memberData.organisation.orgname===""){
        newErrorMemberData.organisation.orgdescription.activate = true;
        newErrorMemberData.organisation.orgdescription.message = "Organisation description require *";
        set({isErrorFound:true});
      }
      
      set({errorMemberData: newErrorMemberData})
    }
  }),
  {
    name: 'interaction-member', // unique name
    storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
}
)
);

export default MemberManagementService;
