
import { request } from "../globalAxios";
import { environment, serviceApi } from "../url";


const getTownAndCountry = async (latitude, longitude) => {
    const response =  await request('get', serviceApi.GET_ALL_EVENT+"lat="+{latitude}+"&lon="+{longitude});
    return response;
}

const log = (message)=>{
    if(!environment.isRomote){
        console.log(message)
    }
}

export {getTownAndCountry,log};