import axios from 'axios';
import { baseUrl, environment } from '../url';
import toast from 'react-hot-toast';
import { log } from '../api/util_api';

const apiClient = axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
});

const apiClientFile = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

const handleException = (error) => {
  let errorMessage = 'An error occurred';
  if (error.response) {
    // Server responded with a status other than 200 range
    errorMessage = error.response.data?.message || error.response.data;
  } else if (error.request) {
    // Request was made but no response was received
    errorMessage = 'Network error, please try again later';
  } else {
    // Something happened in setting up the request
    errorMessage = error.message;
  }
  toast.error(errorMessage);
  return { error: errorMessage };
};

const request = async (method, url, data = null, token = null) => {
  log("-- URL - ",url);
  try {
    const config = {
      method,
      url,
      data,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };

    if (token ) {
      config.headers.Authorization = `Bearer ${token}`;
      
    }

    const response = await apiClient(config);
    log("*************************** global API **************************");
    log(response.data);
    if(!response.data.sucess){
      let exception = response.data.exception;
      if (!exception.message.includes("JWT expired")) {
        toast.error("ERROR! status:\n message: " + exception.message);
      }
      return null;
    }
    return response.data;
  } catch (error) {
    return handleException(error);
  }
};

const requestFile = async (method, url, data = null, token = null) => {
  log("-- URL - ",url);
  try {
    const config = {
      method,
      url,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
      withCredentials: true,
    };

    if (token ) {
      
      config.headers.Authorization = `Bearer ${token}`;
    }
    log("config: ",config);
    log("********************* data **********************",data);

    const response = await apiClientFile(config);
    log("***** global API RESPONCE DATA *******");
    log(response.data.sucess);
    if(!response.data.sucess){
      log("---- eRROR ");
      const exception = response.data.exception;
      log("ERROR! status:"+exception.status+"\n message: "+exception.message+"\n date:"+exception.date)
      toast.error("ERROR! status:"+exception.status+"\n message: "+exception.message);
      return null;
    }
    return response.data;
  } catch (error) {
    return handleException(error);
  }
};

export {request,requestFile};