import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TextField, InputAdornment } from '@mui/material';
import FormErrorFieldMessage from '../form_error_feild';
import FormPayementBankCard from './form_payemrnt_bank_card';
import FormPayementMtnMM from './form_payement_mtn_mm';
import FormPayementOrangeMM from './form_payement_orange_mm';
import ModalPayementService from '../../domain/usecase/modal_payement_service';

const Step2Content = ({
  isTokenValide,
  isconnected,
  toogleShowLogin,
  toogleShow,
  errorCommenderData,
  commenderData,
  setCommenderData,
  isCheckPayment,
  isMtnPayement,
  isOrangePayement,
  toogleIsCheckPayment,
  toogleIsMtnPayement,
  toogleIsOrangePayement
}) => {
  // Initialize Orange Money as default when component mounts
  useEffect(() => {
    toogleIsOrangePayement();
  }, []);

  return (
    <div className='col col-md-8'>
      <form>
        {(!isTokenValide || !isconnected) && (
          <div className='row'>
            <span className="byline">
              <h4>Enter your information to receive ticket</h4>
            </span>
            <div className='row'>
              <div className="col">
                <div className="form-group">
                  <Link to="#" onClick={() => {
                    toogleShowLogin(true);
                    toogleShow();
                  }}>Connect</Link> for a faster experience.
                </div>
              </div>
            </div>
            {/* User Information Fields */}
            <div className="col-md-6">
              <div className="form-group">
                {errorCommenderData.firstName.activate && <FormErrorFieldMessage message={errorCommenderData.firstName.message} />}
                <TextField
                  value={commenderData.firstname}
                  inputProps={{ maxLength: 40 }}
                  onChange={(event) => setCommenderData("firstName", event.target.value)}
                  label="First Name *"
                  placeholder="Enter your first name"
                  sx={{ minWidth: "100%" }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><i className="fa fa-cogs"/></InputAdornment>,
                  }}
                />
              </div>
            </div>
            {/* ... Other user information fields ... */}
          </div>
        )}

        <div className="payment-wrap">
          <span className="byline">
            <h4>Select your payment method</h4>
          </span>
          <div className="form-group">
            <div className="custom-radio-field">
              <div className='row'>
                <label className='payement_option' onClick={toogleIsCheckPayment}>
                  <input 
                    type="radio" 
                    name="payment-method" 
                    value="2" 
                    checked={isCheckPayment}
                    onChange={() => {}}
                  />
                  <span className="radio-field"></span>
                  Check payment <img src="/assets/images/momo/paypal.png" style={{width:"40px"}}/>
                </label>
              </div>
            </div>
            {isCheckPayment && <FormPayementBankCard />}
          </div>
          
          <div className="form-group">
            <div className="custom-radio-field" onClick={toogleIsMtnPayement}>
              <label>
                <input 
                  type="radio" 
                  name="payment-method" 
                  value="3" 
                  checked={isMtnPayement}
                  onChange={() => {}}
                />
                <span className="radio-field"></span>
                MTN Mobile Money <img src="/assets/images/momo/mtn_momo.jpeg" style={{width:"40px"}} alt="" />
              </label>
            </div>
            {isMtnPayement && <FormPayementMtnMM />}
          </div>

          <div className="form-group">
            <div className="custom-radio-field" onClick={toogleIsOrangePayement}>
              <label>
                <input 
                  type="radio" 
                  name="payment-method" 
                  value="4" 
                  checked={isOrangePayement}
                  onChange={() => {}}
                />
                <span className="radio-field"></span>
                Orange Mobile Money <img src="/assets/images/momo/orange_momo.png" style={{width:"40px"}} alt="" />
              </label>
            </div>
            {isOrangePayement && <FormPayementOrangeMM />}
          </div>
        </div>
      </form>
    </div>
  );
};

export default Step2Content; 