import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

//all json file of the english translation for all page
import WELCOME_EN from "./locale/en/welcome";
import WELCOME_EVENT_EN from "./locale/en/welcome_event";
import ABOUTUS_EN from "./locale/en/about";
import COMMON_EN from "./locale/en/common";
import CONTACTUS_EN from "./locale/en/contact";
import EVENTTICKET_EN from "./locale/en/event_ticket";
import WEDDINGCARD_EN from "./locale/en/wedding_card";
import DOWNLOAD_EN from "./locale/en/download";
import MENU_EN from "./locale/en/menu";
import FOOTER_EN from "./locale/en/footer";
import OFFCANVAS_EN from "./locale/en/off_canvas";
//all json file of the french translation for all page
import WELCOME_FR from "./locale/fr/welcome";
import WELCOME_EVENT_FR from "./locale/fr/welcome_event";
import ABOUTUS_FR from "./locale/fr/about";
import COMMON_FR from "./locale/fr/common";
import CONTACTUS_FR from "./locale/fr/contact";
import EVENTTICKET_FR from "./locale/fr/event_ticket";
import WEDDINGCARD_FR from "./locale/fr/wedding_card";
import DOWNLOAD_FR from "./locale/fr/download";
import FOOTER_FR from "./locale/fr/footer";
import MENU_FR from "./locale/fr/menu";
import OFFCANVAS_FR from "./locale/fr/off_canvas";
import { log } from "./data/api/util_api";

const resources = {
    en:{
        welcome: WELCOME_EN,
        welcome_event: WELCOME_EVENT_EN,
        about_us:ABOUTUS_EN,
        common:COMMON_EN,
        contact_us:CONTACTUS_EN,
        download: DOWNLOAD_EN,
        event_ticket:EVENTTICKET_EN,
        wedding_card:WEDDINGCARD_EN,
        footer: FOOTER_EN,
        menu:MENU_EN,
        off_canvas:OFFCANVAS_EN
    },
    fr:{
        welcome: WELCOME_FR,
        welcome_event: WELCOME_EVENT_FR,
        about_us:ABOUTUS_FR,
        common:COMMON_FR,
        download: DOWNLOAD_FR,
        contact_us:CONTACTUS_FR,
        event_ticket:EVENTTICKET_FR,
        wedding_card:WEDDINGCARD_FR,
        menu:MENU_FR,
        footer:FOOTER_FR,
        off_canvas:OFFCANVAS_FR
    }
};

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        default: "en",
        fallbackLng: 'en',
        interpolation:{
            escapeValue:false,
        }
    });


if(sessionStorage.getItem("devent_local_language")!="undefined" && sessionStorage.getItem("devent_local_language")!=null){
    i18n.changeLanguage(sessionStorage.getItem("devent_local_language"));
    log("found language => "+ sessionStorage.getItem("devent_local_language"));
}else{
    sessionStorage.setItem("devent_local_language","en");
    log("not found language => "+ sessionStorage.getItem("devent_local_language"));
}

export default i18n;


