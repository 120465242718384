import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import FormErrorFieldMessage from './form_error_feild';

const RichTextEditor = ({data,editData,errorMessage}) => {
  

  return (
    <>
      <div className="row mt-1">
          <h4>Give us more detail about your event* <FormErrorFieldMessage message={errorMessage}  /></h4>
          <span>This will be your event’s title. Your title will be used to help create your event’s summary, description, category, and tags – so be specific!</span>
      </div>
      <ReactQuill 
        value={data} 
        onChange={(data)=>{
          editData("description",data);
        }} 
        modules={modules}
        formats={formats}
        placeholder="Start typing here..."
      />
     {/* <p>Editor Content:</p>
      <div dangerouslySetInnerHTML={{ __html: data }} />*/}
    </>
  );
};

// Optional: Define the toolbar options for the editor
const modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['link'],
    ['clean']                                         
  ],
};

const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link'
];

export default RichTextEditor;
